import React from "react";
import Search from '../components/Search';
import { useStateValue } from '../statemanagement/StateProvider';
import { actionTypes } from '../statemanagement/reducer';
import "./BasicChat.css"
import { TypeAnimation } from "react-type-animation";
import { modes } from "../mock/PromptAssistanceExamples";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useAuthWrapper } from "./AuthWrapper";
import Header from "./Header";
import Footer from "./Footer";

function BasicChat() {
    const [index, setIndex] = useState(0);
    const [{}, dispatch] = useStateValue();
    useAuthWrapper();

    useEffect(() => {
        dispatch({ type: actionTypes.SET_STREAMING, streaming: false });
        const interval = setInterval(() => {
            setIndex(u => (u + 1) % modes.length)
        }, 4100);
        return () => clearInterval(interval);
    }, [])

    return (
        <div>
            <div className="container">
                <Header showLogin={true} />
                <div className="container_body">
                    <div className="container_copy">
                        <TypeAnimation sequence={[
                            // 'Made by ex-Microsoft Copilot creators',
                            // 500,
                            'Write with AI',
                            500,
                            // 500,
                            // 'Search, Write with AI',
                            // 500,
                            // 'Connected to the Internet',
                            // 500,
                            // 'Live AI News',
                            // 500,
                            // 'Powered by powerful AI models',
                            // 500,
                            // 'Ask followups, Refine Content',
                            // 500,
                            // 'Ask AI anything',
                        ]} wrapper="span" speed={25} style={{ fontSize: "1.3em", lineHeight: "2.1rem", display: "inline-block", color: "rgb(52, 52, 52)", fontWeight: "401", opacity: "0.9" }} repeat={0} />
                        {/* <div style={{opacity: "0.9",fontSize:"1.8em",lineHeight:"2.25rem", fontWeight:420, display:"inline-block"}}>Ask Anything..</div> */}
                    </div>
                    {/**placeholder={placeholders[index]} */}
                    <Search home={true} showButtons={true} mode={"landing"} placeholder={""} route={true} style={{ fontWeight: 400 }} />
                    {/* <Button className="prompt_store_button">Prompt Store, view 100's of examples</Button> */}
                </div>

                {/* if there's other content below, it can be added here */}
                {/* <div style={{margin:"5%"}}>
                <hr />
                <div style={{margin:"2%"}}><a href="/privacy">Privacy Policy</a></div>
            </div> */}
            </div>
            <Footer />
            {/* <div className="footer" style={{ background: "black", opacity: "0.9", position: "fixed", bottom: "0", width: "100%" }}>
                <div className="footer__inner" style={{ padding: "1.5rem", color: "white", display: "flex", fontSize: "13px", fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif' }}>
                    <div style={{ margin: "0 1.8%" }}><a style={{ textDecoration: "none", color: "inherit" }} href="mailto: support@chatdragon.ai">Contact Us</a></div>
                    <div style={{ margin: "0 1.8%" }}><a style={{ textDecoration: "none", color: "inherit" }} href="mailto: support@chatdragon.ai?subject=Re:%20Careers&body=Please%20attach%20your%20CV">Careers</a></div>
                    <div style={{ margin: "0 1.8%" }}><a style={{ textDecoration: "none", color: "inherit" }} href="https://chatdragon.ai/privacy">Privacy</a></div>
                    <div style={{ margin: "0 1.8%" }}><a style={{ textDecoration: "none", color: "inherit" }} href="https://chatdragon.ai/privacy">About Us</a></div>
                </div>

            </div> */}
        </div>



    );
}

export default BasicChat;