import { cancelPaypalPayment, cancelPaypalSubscriptionUrl, createPaypalSubscription, createStripeCheckoutSession, endAuthSession, fetchUser, redirectUri, sessionUser, subscriptionDetailUrl, textGenerationBase, textGenerationBaseFollowup, textGenerationMultiturn, stripeCheckoutRetrieveSessionURL, gptServiceUrl, gptRefineServiceUrl } from "./urls.prod"
import { cancelPaypalPaymentUAT, cancelPaypalSubscriptionUrlUAT, createPaypalSubscriptionUAT, createStripeCheckoutSessionUAT, endAuthSessionUAT, fetchUserUAT, getSubscriptionDetailUrlUAT, gptRefineServiceUrlUAT, gptServiceUrlUAT, redirectUriUAT, sessionUserUAT, stripeCheckoutRetrieveSessionUATURL, subscriptionDetailUrlUAT, textGenerationBaseFollowupUAT, textGenerationBaseUAT, textGenerationMultiturnUAT } from "./urls.uat"

//text-generation
export const getTextGenerationBaseApi = () => window.location.href.includes("localhost") ? textGenerationBaseUAT: textGenerationBase;
export const getTextGenerationMultiturnApi = () => window.location.href.includes("localhost") ? textGenerationMultiturnUAT: textGenerationMultiturn;
export const getTextGenerationFollowupApi = () => window.location.href.includes("localhost") ? textGenerationBaseFollowupUAT: textGenerationBaseFollowup;

//text-generation service helpers
export const getTextGenerationUrl = () => window.location.href.includes("localhost") ? gptServiceUrlUAT: gptServiceUrl;

//refine-generation-service
export const getTextRefinementUrl = () => window.location.href.includes("location") ? gptRefineServiceUrlUAT : gptRefineServiceUrl;

//authentication
export const getRedirectUri = () => window.location.href.includes("localhost") ? redirectUriUAT : redirectUri;
export const getEndAuthSessionApi = () => window.location.href.includes("localhost") ? endAuthSessionUAT: endAuthSession;
export const getSessionUser = () => window.location.href.includes("localhost") ? sessionUserUAT : sessionUser;
export const fetchUserFromCode = () => window.location.href.includes("localhost") ? fetchUserUAT: fetchUser;

//payments
export const getCancelPaypalSubscriptionUrl = () => window.location.href.includes("localhost") ? cancelPaypalSubscriptionUrlUAT: cancelPaypalSubscriptionUrl;
export const getCreatePaypalSubscriptionUrl = () => window.location.href.includes("localhost") ? createPaypalSubscriptionUAT : createPaypalSubscription;
export const getSubscriptionDetailUrl = () => window.location.href.includes("localhost") ? subscriptionDetailUrlUAT: subscriptionDetailUrl;

//stripe intergration
export const getStripeCheckoutUrl = () => window.location.href.includes("localhost") ? createStripeCheckoutSessionUAT: createStripeCheckoutSession;
export const getStripeCheckoutRetrieveSessionUrl = () => window.location.href.includes("localhost") ? stripeCheckoutRetrieveSessionUATURL: stripeCheckoutRetrieveSessionURL;