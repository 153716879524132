import React from 'react';
import { useNavigate } from "react-router-dom"
import { useAuthWrapper } from './AuthWrapper';
import Header from './Header';
import Footer from './Footer';

const CustomerSupport = () => {

    const navigate = useNavigate();
    useAuthWrapper();

    return (
        <div>
            <div className="container">
                <Header showLogin={false} />
                <div style={{ marginTop: "2%" }}>
                    <div className="">
                        <div className="privacy-policy" style={{ marginTop: "7%" }}>
                            <h1 style={{fontWeight:400}}>Customer Support</h1>
                            <br />
                            <p><i>Last updated: November 28th, 2023</i></p>
                            <br /><br />
                            <h2>Our Services</h2>
                            <br />
                            <p>
                                WriteChat.ai offers a text generation service for writing assistance. WriteChat.ai can be accessed through{' '}
                                <a href="https://www.writechat.ai">https://www.writechat.ai</a> and its associated internet domains.
                            </p>
                            <br />
                            <h2>Privacy Policy</h2>
                            <br />
                            <p>
                                Our Privacy Policy can be accessed via{' '}
                                <a href="https://www.writechat.ai/privacy">https://www.writechat.ai/privacy</a> and its associated internet domains.
                            </p>
                            
                           
                            {/* Add more sections here... */}

                            <br />
                            <h2>Contact Us</h2>
                            <br />
                            <p>If you have any inquiries regarding this Policy or any other query, kindly reach out to us at <a href="mailto:support@chatdragon.ai">support@chatdragon.ai</a>.</p>
                            <br />
                      
                            <br />
                            <p style={{fontSize:"0.9rem"}}>Address: G-9/15, Phase 1, SikanderpurGhosi, 122002, Contact: (+91) 9815282408</p>
                            <br />
                            <br />
                        </div>
                        <br />

                    </div>
                    <br />

                </div>

            </div>
            <Footer/>
        </div>


    );
};

export default CustomerSupport;
